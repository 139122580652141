import React from "react";
import Layout from "../components/layout";

const NotFound = () => (
  <Layout>
    <h1>emptiness form-o-four</h1>
  </Layout>
);

export default NotFound;
